import React, { useEffect, useState } from 'react';
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Spin,
  Divider,
  Typography,
  Alert,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.css';
import { useNavigate } from 'react-router-dom';
// import { User } from '../../types/user';
// import { getActiveSession, saveSession } from '../../services/authentication';
// import getSession from '../../services/sessionService';

const { Content } = Layout;
const { Title } = Typography;

function Login() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /* const login = (user: User) => {
    setSubmitting(true);
    setLoginError(false);

    getSession(user)
      .then(async (response) => {
        saveSession(response);
        navigate('/');
      })
      .catch(() => setLoginError(true))
      .finally(() => setSubmitting(false));
  }; */

  useEffect(() => {
    const activeSession = null; // getActiveSession();

    if (activeSession !== null) {
      navigate('/');
    }
  });

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ margin: 0, padding: 24 }}>
        <Row
          align="middle"
          justify="center"
          style={{ padding: '2%', height: 'inherit' }}
        >
          <Col lg={{ span: 8 }} md={{ span: 18 }} sm={{ span: 22 }}>
            <Card>
              <Row justify="center">
                <Title level={3} className="title">
                  JMSG
                </Title>
              </Row>
              <Divider />
              <Spin
                spinning={submitting}
                indicator={
                  <LoadingOutlined style={{ fontSize: '44pt' }} spin />
                }
              >
                <Form
                  form={form}
                  layout="vertical"
                  className="form-login"
                  // onFinish={login}
                >
                  {loginError && (
                    <Alert
                      message="Seu usuário e/ou senha está errado"
                      type="warning"
                      showIcon
                      className="error-message"
                    />
                  )}
                  <Form.Item
                    label="Usuário"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Digite o seu usuário',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Senha"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Digite a sua senha',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      className="submit-button"
                    >
                      Entrar
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Login;
