import React from 'react';
import { Row, Col, Form, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { QuestionItemProps } from './question-item.types';

function QuestionItem({ item, questionOrder, selected }: QuestionItemProps) {
  const [value, setValue] = React.useState<number>();
  const options = [1, 2, 3, 4, 5, 6, 7];

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <>
      <p>{item.text}</p>
      <Row justify="center" align="middle" gutter={12}>
        <Col style={{ paddingBottom: '24px' }}>Discordo totalmente</Col>
        <Col>
          <Form.Item
            name={`behavior-q${questionOrder}i${item.order}`}
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório. Preencha, por favor.',
              },
            ]}
          >
            <Radio.Group
              name={`behavior-q${questionOrder}i${item.order}`}
              value={value}
              onChange={onChange}
            >
              {options.map((option) => (
                <Radio
                  key={option}
                  value={option}
                  disabled={selected.includes(option) && option !== value}
                />
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col style={{ paddingBottom: '24px' }}>Concordo totalmente</Col>
      </Row>
    </>
  );
}

export default QuestionItem;
