import React from 'react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import Router from './components/router';
import './style.css';

function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <Router />
    </ConfigProvider>
  );
}

export default App;
