import api from './api';
import { NewUser } from '../types/user';

const saveUser = async (user: NewUser): Promise<{ insertId: number }> => {
  return api
    .post(`/costumer`, user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export default saveUser;
