import api from './api';
import { Test, TestAnswer } from '../types/test';

const getTest = async (code: string): Promise<Test> => {
  return api.get(`v1/reviews/${code}`).then(({ data }) => data);
};

const saveTest = async (test: TestAnswer): Promise<void> => {
  return api.post(`/testAnswersSubmission`, test).then(({ data }) => data);
};

export { getTest, saveTest };
