import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Layout,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Divider,
  Input,
  DatePicker,
  Select,
  Spin,
  message,
} from 'antd';
import { NewUser } from '../../types/user';
import saveUser from '../../services/user';

const { Content } = Layout;
const { Title } = Typography;

function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const saveNewUser = (user: NewUser) => {
    setLoading(true);

    saveUser({
      ...user,
      birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
    })
      .then(({ insertId }) => {
        navigate(`/?id=${insertId}`);
      })
      .catch(() => {
        message.error('Erro ao tentar salvar seu dados. Tente novamente!', 0);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ margin: 0, padding: 24 }}>
        <Row
          align="middle"
          justify="center"
          style={{ padding: '2%', height: 'inherit' }}
        >
          <Col lg={{ span: 8 }} md={{ span: 18 }} sm={{ span: 22 }}>
            <Card>
              <Row justify="center">
                <Title level={3} className="title">
                  APA EIVE
                </Title>
              </Row>
              <Divider />
              <Spin spinning={loading}>
                <Form
                  form={form}
                  layout="vertical"
                  className="form-survey"
                  onFinish={saveNewUser}
                >
                  <Form.Item
                    label="Nome completo"
                    name="name"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Digite o seu nome completo',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Data de nascimento"
                    name="birthDate"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Selecione a sua data de nascimento',
                      },
                    ]}
                  >
                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    label="E-mail"
                    name="emailAdress"
                    hasFeedback
                    rules={[
                      {
                        type: 'email',
                        message: 'E-mail inválido',
                      },
                      {
                        required: true,
                        message: 'Digite o seu e-mail',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Telefone"
                    name="phoneNumber"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Digite o seu telefone',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Gênero"
                    name="gender"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message:
                          'Digite o seu gênero                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ',
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="Masculino">Masculino</Select.Option>
                      <Select.Option value="Feminino">Feminino</Select.Option>
                      <Select.Option value="Outro">Outro</Select.Option>
                      <Select.Option value="Não quero declarar">
                        Não quero declarar
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      disabled={loading}
                    >
                      Ir para o teste
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default SignUp;
