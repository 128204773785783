const questions = [
  {
    order: 1,
    text: 'Aprendo de forma significativa quando...',
    items: [
      {
        order: 1,
        text: 'Interpreto diagramas, esquemas, gráficos, infográficos, mapas, imagens e objetos por vários ângulos, inclusive se os observasse de cima, panoramicamente.',
      },
      {
        order: 2,
        text: 'Compreendo melhor as informações por meio de manuais, textos, folhetos, livros e documentos.',
      },
      {
        order: 3,
        text: 'Participo ativamente de palestras, debates, discussões e reuniões em grupo de forma presencial ou online.',
      },
      {
        order: 4,
        text: 'Sou ativo e me envolvo em atividades práticas colocando a “mão na massa”, pois possuo dificuldade em permanecer muito tempo quieto.',
      },
    ],
  },
  {
    order: 2,
    text: 'Aprendo de forma significativa quando...',
    items: [
      {
        order: 1,
        text: 'Imagino a aparência das palavras em minha mente antes de lê-las, falá-las ou escrevê-las.',
      },
      {
        order: 2,
        text: 'Concentro-me, de forma habitual, no que estou ouvindo nas aulas, nos vídeos, nos canais de áudio para ouvir músicas, em jogos, em debates, com podcasts, com audiobooks ou para lembrar de algumas palavras depois de esquecer a sua grafia, pronuncio em voz alta, depois de ouvi-las.',
      },
      {
        order: 3,
        text: 'Manuseio aparelhos, jogos ou objetos antes de ler suas instruções, testando na prática e realizando até pequenos experimentos.',
      },
      {
        order: 4,
        text: 'Escrevo palavras ou textos em um papel ou aplicativos, a fim de conseguir praticidade para lembrar futuramente.',
      },
    ],
  },
  {
    order: 3,
    text: 'Aprendo de forma significativa quando...',
    items: [
      {
        order: 1,
        text: 'Ao fechar os olhos, visualizo as imagens de um ambiente e do comportamento das pessoas, concentrando-me na aparência de tudo e recriando em rascunhos algo que aconteceu.',
      },
      {
        order: 2,
        text: 'Presto atenção ao ouvir as histórias dos amigos, de familiares ou de gravações.',
      },
      {
        order: 3,
        text: 'Aproveito o tempo para fazer a leitura de textos interessantes em livros, revistas ou internet, prestando atenção ao conteúdo e adicionando novos conceitos e palavras aprendidas ao meu conhecimento e vocabulário.',
      },
      {
        order: 4,
        text: 'Crio com determinação projetos e experimentos criativos para executar em meu cotidiano, acompanhando os resultados do meu trabalho.',
      },
    ],
  },
  {
    order: 4,
    text: 'Aprendo de forma significativa quando...',
    items: [
      {
        order: 1,
        text: 'Faço listas, anotações e atividades para me organizar, cumprindo o que planejei para continuar aprendendo.',
      },
      {
        order: 2,
        text: 'Crio músicas para ajudar na memorização de conceitos básicos e palavras-chave, canto, leio em voz alta, ouço audiobooks e podcasts, escuto músicas enquanto estudo ou leio ou quando toco um instrumento.',
      },
      {
        order: 3,
        text: 'Imagino situações pelas quais ainda passarei, simulando mentalmente os cenários, de modo que essa prática possa me auxiliar na preparação para lidar com os eventos futuros.',
      },
      {
        order: 4,
        text: 'Priorizo a leitura de instruções antes de usar um novo aparelho, jogos ou objetos, para aprender sem grandes dúvidas o seu modo de utilização.',
      },
    ],
  },
  {
    order: 5,
    text: 'Quando estou aprendendo, percebo que...',
    items: [
      {
        order: 1,
        text: 'Consigo lidar e ter acesso aos meus próprios sentimentos, sonhos e ideias.',
      },
      {
        order: 2,
        text: 'Avalio logicamente uma grande quantidade de informações específicas para a tomada de decisões..',
      },
      {
        order: 3,
        text: 'Observo, escuto e reflito com atenção, paciência, cuidado e organização. Penso cuidadosamente sobre o que vou responder.',
      },
    ],
  },
  {
    order: 6,
    text: 'Quando estou aprendendo, percebo que...',
    items: [
      {
        order: 1,
        text: 'Tenho facilidade para cálculos mentais e interpreto logicamente situações que as pessoas dizem ou fazem.',
      },
      { order: 2, text: 'Confio em palpites, impressões e na minha intuição.' },
      {
        order: 3,
        text: 'Antes de iniciar uma atividade de estudos, penso com muito cuidado e costumo fazer um planejamento detalhado.',
      },
    ],
  },
  {
    order: 7,
    text: 'Quando estou aprendendo, percebo que...',
    items: [
      {
        order: 1,
        text: 'Busco a explicação racional das ideias que estudo, identificando padrões, regularidades ou sequências lógicas nos acontecimentos.',
      },
      {
        order: 2,
        text: 'Tenho concentração, tranquilidade e cuidado na tomada de decisão.',
      },
      {
        order: 3,
        text: 'Possuo sentimentos, reações fortes e envolvimento pessoal com o assunto que estou estudando.',
      },
    ],
  },
  {
    order: 8,
    text: 'Quando estou aprendendo, percebo que...',
    items: [
      {
        order: 1,
        text: 'Priorizo as interações pessoais e invisto meu tempo livre em jogos de tabuleiro ou esportes coletivos.',
      },
      {
        order: 2,
        text: 'Considero todas as possibilidades de uma situação ou de uma informação e procuro me colocar na posição da outra pessoa.',
      },
      {
        order: 3,
        text: 'Analiso as situações passo a passo e aprofundo-me em seus pequenos detalhes com o intuito de relacioná-los.',
      },
    ],
  },
  {
    order: 9,
    text: 'No meu ponto de vista...',
    items: [
      {
        order: 1,
        text: 'Considero um desafio criar algo original na maior parte das vezes.',
      },
      {
        order: 2,
        text: 'Costumo ter uma interpretação diferente dos outros, perante a leitura de um texto e gosto de pensar de forma original e criativa.',
      },
      {
        order: 3,
        text: 'Fico desanimado quando não sou elogiado, já que as pessoas recorrem a mim como líder para pedir conselhos e para que eu as ensine coisas que sei fazer.',
      },
      {
        order: 4,
        text: 'Prefiro, sozinho, decidir o que, quando e como fazer algo, meditar, refletir ou pensar sobre questões importantes da vida e vivenciar situações nas quais aprendo mais sobre mim mesmo.',
      },
      {
        order: 5,
        text: 'Costumo reagir rapidamente para responder a uma pergunta ou para uma tomada de decisão.',
      },
      {
        order: 6,
        text: 'Ao iniciar uma tarefa, examino uma grande quantidade de dados, buscando relação entre eles.',
      },
    ],
  },
  {
    order: 10,
    text: 'No meu ponto de vista...',
    items: [
      { order: 1, text: 'Cumpro as orientações dadas sem questionar.' },
      {
        order: 2,
        text: 'Sou capaz de formular respostas originais e criativas com frequência.',
      },
      {
        order: 3,
        text: 'Prefiro conteúdos mais organizados logicamente a partir de roteiros já elaborados por professores ou outras pessoas.',
      },
      {
        order: 4,
        text: 'Sou bem disciplinado, organizado com meu planejamento de estudos, possuo a capacidade de resiliência, determinação e a vontade de investir em mim.',
      },
      {
        order: 5,
        text: 'Costumo distribuir meu tempo de estudos sem tanta organização.',
      },
      {
        order: 6,
        text: 'Costumo enfatizar o contexto global e não os aspectos específicos das tarefas que realizo.',
      },
    ],
  },
  {
    order: 11,
    text: 'No meu ponto de vista...',
    items: [
      { order: 1, text: 'Costumo aceitar as regras estabelecidas.' },
      {
        order: 2,
        text: 'Sou empolgado em ousar e tentar criar algo diferente por meio de novas experiências e desafios.',
      },
      {
        order: 3,
        text: 'Prefiro professores que se preocupam mais com o conteúdo àqueles que priorizam as relações pessoais estabelecidas na sala de aula.',
      },
      {
        order: 4,
        text: 'Por ter pressa em estudar, acabo sendo desatento com algumas atividades.',
      },
      {
        order: 5,
        text: 'Ao ler um texto presto mais atenção na ideia geral do que nos detalhes informativos.',
      },
    ],
  },
  {
    order: 12,
    text: 'Consigo aprender de forma mais fácil e empolgante quando...',
    items: [
      {
        order: 1,
        text: 'Trabalho coletivamente, participando de atividades de aprendizado com os amigos.',
      },
      {
        order: 2,
        text: 'Tento resolver quebra-cabeças, sair de labirintos de jogos visuais, costurar, tricotar, trabalhar com carpintaria ou modelagem.',
      },
      {
        order: 3,
        text: 'Pratico esporte, atividade física ou aprendo uma nova habilidade motora, como desenhar ou pintar.',
      },
    ],
  },
  {
    order: 13,
    text: 'Consigo aprender de forma mais fácil e empolgante quando...',
    items: [
      {
        order: 1,
        text: 'Leio conteúdos da língua portuguesa, língua estrangeira ou Ciências Humanas e explico o sentido das palavras que uso, procurando o significado dos termos que desconheço no dicionário.',
      },
      {
        order: 2,
        text: 'Consigo achar um caminho em lugares desconhecidos, registrando o que vejo ao meu redor.',
      },
      {
        order: 3,
        text: 'Ouço música e a reproduzo batucando ou cantando melodias, enquanto trabalho, estudo ou aprendo algo novo.',
      },
      {
        order: 4,
        text: 'Realizo minhas atividades de estudos interagindo com a natureza e lendo sobre temas de meio ambiente e animais de estimação.',
      },
    ],
  },
  {
    order: 14,
    text: 'Consigo aprender de forma mais fácil e empolgante quando...',
    items: [
      {
        order: 1,
        text: 'Presto mais atenção nas palavras escritas em placas ou anúncios do que na paisagem.',
      },
      {
        order: 2,
        text: 'Participo de atividades desafiadoras ou experiências físicas emocionantes e radicais.',
      },
      {
        order: 3,
        text: 'Visito zoológicos, aquários ou outros mundos naturais e percebo as diferenças entre os tipos de árvores e animais.',
      },
    ],
  },
];

export default questions;
