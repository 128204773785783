import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Form,
  Divider,
  Spin,
  Typography,
  message,
} from 'antd';
import { LeftOutlined, RightOutlined, SendOutlined } from '@ant-design/icons';
import Question from './question';
import { saveTest } from '../../services/test';
import './style.css';
import questions from './questions';
import { TestAnswer } from '../../types/test';

const { Title } = Typography;

function Test() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [activeQuestion, setActiveQuestion] = React.useState<number>(1);
  const [form] = Form.useForm();
  const [selected, setSelected] = React.useState<number[][]>();
  const [answered, setAnswered] = React.useState<boolean[][]>();
  const [questionsSize, setQuestionsSize] = React.useState<number>();
  const [idCostumer, setIdCostumer] = React.useState<number>(0);
  const [search] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const id = search.get('id');

    if (!id) {
      navigate('/signup');
    }

    const newSelected: number[][] = Array(questions.length).fill([]);
    const newAnswered: boolean[][] = Array(questions.length).fill([]);

    questions.forEach((question) => {
      newAnswered[question.order - 1] = Array(question.items.length).fill(
        false
      );
    });

    setIdCostumer(Number(id));
    setSelected(newSelected);
    setAnswered(newAnswered);
    setQuestionsSize(questions.length);
    setLoading(false);
  }, []);

  const sendTest = async (answers: object) => {
    setLoadingSave(true);

    const testAnswer: TestAnswer = { answers, idCostumer };

    saveTest(testAnswer)
      .then(() => navigate('/finish'))
      .catch(() => {
        message.error('Erro ao tentar salvar o teste. Tente novamente!', 0);
      })
      .finally(() => setLoadingSave(false));
  };

  const handleSelected = (_changed: object, allFields: any) => {
    const fields: string[] = Object.keys(allFields);
    const newSelected: number[][] = Array(questionsSize).fill([]);
    const newAnswered: boolean[][] = Array(questionsSize).fill([]);

    fields.forEach((k: string) => {
      const index = Number(k.split(/[qi]/)[2]) - 1;

      newAnswered[index] = [...newAnswered[index], allFields[k] !== undefined];

      if (allFields[k]) {
        newSelected[index] = [...newSelected[index], allFields[k]];
      }
    });

    setAnswered(newAnswered);
    setSelected(newSelected);
  };

  return (
    <Row style={{ background: '#f0f2f5' }}>
      <Col
        lg={{ span: 16, offset: 4 }}
        md={{ span: 20, offset: 2 }}
        sm={{ span: 22, offset: 1 }}
        className="test"
      >
        <Spin spinning={loading}>
          <>
            <Row justify="center">
              <Title level={3}>Teste APA EIVE</Title>
            </Row>
            <Divider />

            <Spin spinning={loadingSave}>
              <Row gutter={8}>
                {questions.map((question) => (
                  <Col key={question.order}>
                    <Button
                      type={
                        (answered &&
                          answered[question.order - 1].every(
                            (answer) => answer === true
                          )) ||
                        activeQuestion === question.order
                          ? 'primary'
                          : 'default'
                      }
                      ghost={
                        activeQuestion === question.order &&
                        answered &&
                        answered[question.order - 1].some(
                          (answer) => answer === false
                        ) === true
                      }
                      shape="circle"
                      size="small"
                      onClick={() => setActiveQuestion(question.order)}
                    >
                      {question.order}
                    </Button>
                  </Col>
                ))}
              </Row>

              <Form
                form={form}
                layout="vertical"
                className="form-survey"
                onValuesChange={handleSelected}
                onFinish={sendTest}
              >
                {questions.map((question) => (
                  <Question
                    key={question.order}
                    question={question}
                    selected={selected ? selected[question.order - 1] : []}
                    active={question.order === activeQuestion}
                  />
                ))}
                <Divider />
                <Row justify="space-between" style={{ marginBottom: '2%' }}>
                  <Button
                    shape="round"
                    disabled={activeQuestion === 1}
                    onClick={() => setActiveQuestion(activeQuestion - 1)}
                  >
                    <LeftOutlined />
                    Anterior
                  </Button>
                  <Button
                    shape="round"
                    disabled={activeQuestion === questionsSize}
                    onClick={() => setActiveQuestion(activeQuestion + 1)}
                  >
                    Próxima
                    <RightOutlined />
                  </Button>
                </Row>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    shape="round"
                    block
                    htmlType="submit"
                    className="send-btn"
                    icon={<SendOutlined />}
                    style={{ fontWeight: 700 }}
                    loading={loading || loadingSave}
                    disabled={
                      loading ||
                      loadingSave ||
                      (answered &&
                        answered.flat().some((answer) => answer === false))
                    }
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </>
        </Spin>
      </Col>
    </Row>
  );
}

export default Test;
