import React from 'react';
import { Result, Button } from 'antd';

function FinishTest() {
  return (
    <Result
      status="success"
      title="Teste finalizado com sucesso!"
      extra={[<Button href="https://apaeive.com.br">Ir para o início</Button>]}
    />
  );
}

export default FinishTest;
