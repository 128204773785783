import axios, { AxiosResponse } from 'axios';

const api = axios.create({ baseURL: 'https://api.apaeive.com.br' });

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    const erro = error.response.data.errors && error.response.data.errors[0];

    throw new Error(erro);
  }
);

export default api;
