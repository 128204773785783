import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../login';
import Test from '../test';
import SignUp from '../signup';
import FinishTest from '../finishTest';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Test />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/finish" element={<FinishTest />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
